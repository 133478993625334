
exports.onClientEntry = () => {
  console.log("bApi We've started!", window)
  window.dispatchEvent(new Event('resize'));
}

exports.onInitialClientRender = () => {
  console.log("bApi ReactDOM.render has executed")
  window.dispatchEvent(new Event('resize'));
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log('bApi new pathname', location.pathname)
  console.log('bApi old pathname', prevLocation ? prevLocation.pathname : null)
}
